import React, { Component, useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import {latestContent} from "../contents/Db_ContentLoader"
import {Grid, Paper, Typography, Button, Divider} from "@material-ui/core"
import parse from 'html-react-parser';
import {TextStyles} from "../utils/uistyle/TextStyles"


const Latest = ()=>{
    const [items, setItems] = useState([]);
    const tempList = [];

    useEffect(() =>{
        const snapshot = latestContent();
        snapshot.then((querySnapshot) =>{
               querySnapshot.forEach((doc) => {
               let item = doc.data();
               item.id = doc.id;            
               tempList.push(item);
           });
   
           setItems(tempList);
           console.log(items);
        });
   
       }, []);
    
    return(<>
        <Grid container spacing={2} style={{marginTop:"2em"}}>
            {items.map(itm =>(<ContentItem key={itm.id} item = {itm} />))}
        </Grid> 
    </>);
}


export default Latest;

/*--------------------------------------------------------------------*/
const ContentItem = ({item})=>{
    const textStyle = TextStyles();
    const plain = item.pageContent.replace(/(<([^>]+)>)/ig, '');

    return(
    <>
        <Grid item xs={"12"} md={"7"} lg={"7"} style={{margin:"0 auto"}}>
            <Paper style={{padding:"1em"}}>
                <Typography variant="h5" className={textStyle.styledHead}>{item.headLine}</Typography>
                <Typography variant="subtitle" className={textStyle.styledHead}>{item.author}</Typography>
                <Typography variant="p" paragraph="true">{parse(plain.substring(0, 250))}</Typography>
                <Button variant="outlined"  component={Link} target="_blank" to={"/content/read/"+item.id}>READ MORE</Button>
            </Paper>
        </Grid>
    </>
    );
}

