import React, { useEffect } from 'react';
import {Link} from 'react-router-dom';
import {useSelector, useDispatch} from "react-redux";
import {AppBar, Toolbar, IconButton, makeStyles, Typography } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle  from '@material-ui/icons/AccountCircle';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import {TextStyles} from "../utils/uistyle/TextStyles"

const Header = () => {
    const logged = useSelector(state => state.isLogged);
    const textStyle = TextStyles();

    const checkOffline = ()=>{
    };

    useEffect(() =>checkOffline(), []);

     return (
       <>
       <AppBar position="static" style={{ margin: 0 }}>
            <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu">
                <MenuIcon />
            </IconButton>
                <Typography variant="h4" className={textStyle.brandName} style={{flexGrow:1}} component={Link} to="/">
                    ASSAMESE-ONLINE
                </Typography>

                <Link style={{color:"inherit"}} to={logged ? "/user/profile" : "/user/signin"}>
                    <IconButton color="inherit"> <AccountCircle /> </IconButton>
                </Link>
            </Toolbar>
        </AppBar>
    </>
     )
}


export default Header;