import React, { useEffect } from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {useSelector, useDispatch} from "react-redux";
import Header from "./global/Header";
import Main from "./global/Main";
import firebase from "firebase/app";
import {firebaseConfig} from "./conf/firebaseConfig";
import { getUser } from './models/localStores';

const App = ()=>{
  const app       = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
  const dispatch  = useDispatch();
  
  useEffect(() => {
    const user      = getUser();
    if(user.id.length >0){
        dispatch({type: "SIGN_IN"});
    }else{
        dispatch({type: "SIGN_OUT"});
    }
  }, []);

  return (
    <Router>
      <Header />
      <Main />
    </Router>
  );
}

export default App;
