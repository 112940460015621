const dbtask = (state = 0, action) =>{
    switch (action.type) {
        case "STARTED"  : return 100;
        case "SUCCESS"  : return 200;
        case "FAILED"   : return 300;
        case "FINISHED" : return 400;
    }

    return state;
}

export default dbtask;