import {Grid, Paper, Typography, Button, Divider, LinearProgress} from "@material-ui/core"
import {TextStyles} from "../utils/uistyle/TextStyles";
import firebase from 'firebase/app';
import "firebase/auth";
import { useState } from "react";
import {getUserByEmail } from "../users/Db_UserLoader";
import {SignupUser} from "../users/Db_UserSave";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { localKeys } from "../models/localStores";

const SignIn = ()=>{
    const txtStyle = TextStyles();
    const [user, setUser] = useState(null);
    const [firebaseUser, setFirebaseUser] = useState(null);
    const [progress, setProgress]  = useState(false);
    const [msgTxt, setMsg]         = useState("");
    const [msgColor, setMsgColor]  = useState("inherit");
    const history = useHistory();
    const dispatch = useDispatch();

    const handleClick = ()=>{
        console.log("Signing in to google...");
        let provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider)
        .then((result)=>{
            let usr = result.user;
            setFirebaseUser(usr);
            console.log(JSON.stringify(usr));
            signinUser(usr);
            })
        .catch((err)=>{
            setMsg(err);
            setProgress(false);
            console.log(err);
        });
    }

    const signinUser = async (usr) =>{
         setProgress(true);
         setProgress("Please wait...");

         const response = await getUserByEmail(usr.email);
         if(response.size == 0){
            signupUser(usr);
         }else{
            response.forEach((doc) =>{
                let _user = doc.data();
                if(_user.email === usr.email){
                    _user.id = doc.id;
                    setUser(_user);
                    setMsg(_user.id + " " + _user.email);

                    localStorage.setItem(localKeys.USERS, JSON.stringify(_user));
                    dispatch({type:"SIGN_IN"});
                    history.push("/user/profile");
                }
            });
        }
    }

    const signupUser = async (usr) =>{
          let _user = {
            email       : usr.email,
            userImage   : usr.photoURL,
            name        : usr.displayName,
            lastActive  : (new Date()).getTime(),
            phoneNo     : "",
            access      : "user",
            appVersion  : "21.06.06",
            isActive    : "Y",
            userAgent   : "WEB",
            fcmNo       : ""
          }

          setMsg("Please wait while registering..");
          const resp = await SignupUser(_user);

          if(resp.id.length > 0){
               _user.id = resp.id;
               setUser(_user);
               localStorage.setItem("auth", JSON.stringify(_user));
               dispatch({type:"SIGN_IN"});
               history.push("/profile");
          }
    }


    return(
        <Grid container spacing={0} alignItems="center" justify="center" style={{marginTop:"2em"}}>
            <Grid item xs={12}  sm={12} lg={4} md={6} style={{margin:"0 auto"}}>
            <Paper variant="elevation" square style={{padding:"20px"}}>
            <Typography className={txtStyle.engHeadline} style={{marginBottom:"20px"}} variant="h4">Sign in</Typography>
            
            
            <Typography variant="body1" 
                style={{marginBottom:"20px", visibility: progress ? "visible" : "hidden"}}>{msgTxt}</Typography>
            
            <LinearProgress style={{visibility: progress ? "visible" : "hidden"}} />
            <Button variant="outlined" color="secondary" style={{visibility: progress ? "hidden" : "visible"}}
            onClick={handleClick}>Sign in with Google </Button>
            </Paper>
            </Grid>
            </Grid>
    )
}

export default SignIn;