export const docList ={
       USERS : "USERS",
       CONTENTS : "CONTENTS",
};

/*
export const docList ={
       USERS : "TEST_USERS",
       CONTENTS : "TEST_CONTENTS",
};

*/
