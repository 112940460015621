import {Grid, Paper, Typography, Button, Divider, LinearProgress} from "@material-ui/core";
import MyPosts from "../myposts/MyPosts";
import {useSelector, useDispatch} from "react-redux";
import { useHistory } from 'react-router-dom';

const Profile = ()=>{
    const hist   = useHistory();
    const logged = useSelector(state => state.isLogged);
    if(!logged){ hist.push ("/user/signin");}

    console.log("USER LOGGED IN... " + logged);
    return(
    <>
        <MyPosts />
    </>
    )
}

export default Profile;