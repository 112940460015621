import { Grid, Button, Typography } from "@material-ui/core";
import React from "react";
import {Link} from "react-router-dom";
import Icon from '@material-ui/core/Icon';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const ErrPage = ()=> {
    return(
        <>
        <Grid container spacing={0} style={{marginTop:"2em"}}>
            <Grid item xs={12} sm={12} lg={6} md={6} style={{margin:"0 auto"}}>
            <Typography variant="h2">
                 Error 404!
            </Typography>
            <Typography variant="h5">
                The page you searched is not found. May be you have clicked a broken link.
            </Typography>
            <Button
                style={{marginTop:"2em"}}
                component={Link} to="./" 
                variant="outlined" 
                startIcon={<ArrowBackIosIcon />} >Back to home</Button>
        </Grid></Grid>
    </>
    );
    
}

export default ErrPage;