import firebase from 'firebase/app';
import 'firebase/firestore';
import {docList} from "../models/DocList";
import {getUser} from "../models/localStores";


export const latestContent = () =>{
    console.log("loading latest content");
    return firebase.firestore().collection(docList.CONTENTS).orderBy("updateOn", "desc").limit(10).get();
}


export const byUserId = () =>{
    let user    = getUser();
    return firebase.firestore().collection(docList.CONTENTS)
            .where("createBy" , "==", user.id)
            .get();
            // .orderBy("updateOn", "desc").limit(20).get();
}


export const loadById = (itemId) =>{
    return firebase.firestore().collection(docList.CONTENTS).doc(itemId).get();
}