export const firebaseConfig = {
  apiKey: "AIzaSyCcT75DP7DUVarqKfcdLBf57XHEkOXrs7s",
  authDomain: "assameseon.firebaseapp.com",
  projectId: "assameseon",
  storageBucket: "assameseon.appspot.com",
  messagingSenderId: "408784791300",
  appId: "1:408784791300:web:9b59f10a52d35514875251",
  measurementId: "G-TB8FKVQHSF"
};

