import firebase from 'firebase/app';
import 'firebase/firestore';
import {docList} from "../models/DocList";


export const updateContent = async (id, headline, page) =>{
    return await firebase.firestore().collection(docList.CONTENTS).doc(id).update({
         headLine : headline,
         pageContent: page
     });

}

