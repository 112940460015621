import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {useSelector} from "react-redux";
import { Container } from '@material-ui/core';

import Home      from "../pages/Home";
import ErrPage   from "../pages/ErrPage";
import Latest    from "../contents/Latest";
import Read      from "../contents/Read";
import Profile   from "../users/Profile";
import SignIn    from "../users/SignIn";

import MyPosts_View      from "../myposts/MyPosts_View";
import MyPosts_Edit      from "../myposts/MyPosts_Edit";


const Main = () => {
    return(
        <Container>
            <Switch>
                <Route exact path = "/" component ={Home} />
                <Route exact path = "/content/Latest" component ={Latest} />
                <Route exact path = "/content/read/:id" component ={Read} />
                <Route exact path = "/user/profile" component ={Profile}/>
                
                <Route exact path = "/user/signin" component ={SignIn} />
                <Route exact path = "/myposts/view/:id" component ={MyPosts_View} />
                <Route exact path = "/myposts/edit/:id" component ={MyPosts_Edit} />
                {/*<Route exact path = "/signup" component ={Signup} />
                <Route exact path = "/cart" component ={ViewCart} />

                 <Route exact path = "/products/add" component ={Product_Add} />

                 

                <Route exact path = "/store/:storeid" component ={StoreHome} />
                
                <Route exact path = "/signin" component ={SignIn} />
                <Route exact path = "/signin_verify" component ={SignInPass} />
                <Route exact path = "/register" component ={Register} /> */ }
                <Route component={ErrPage} />
            </Switch>
    </Container>
    )
}

export default Main;
