export const getUser = () =>{
     const strs = localStorage.getItem(localKeys.USERS);
     if(strs != null){
        const user = JSON.parse(strs);
        console.log("USER ID: " + user.id);
        return user;
     }
     
     return {id : ""}
}

export const setUser = () =>{
    
}

export const localKeys = {
    USERS : "assamese-users"
}