import { Grid, Paper, Typography, Button, Divider, Avatar } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import {loadById} from "../contents/Db_ContentLoader";
import parse from 'html-react-parser';
import {TextStyles} from "../utils/uistyle/TextStyles";

const MyPosts_View = () =>{
    const textStyle = TextStyles();
    const {id} = useParams();
    const [content, setContent] = useState([]);
    const [pageCont, setPageCont] = useState("<p></p>");

    const fetchContent = async ()=>{
        let snapshot = await loadById(id);
        let data = snapshot.data();
        console.log(data);
        setContent(data);    

        setPageCont(data.pageContent);
    }

    useEffect(() =>fetchContent(), []);

    return(
        <Grid container spacing={0} style={{marginTop:"2em"}}>
            <Grid  item xs={"12"} md={"8"} lg={"8"} style={{margin:"0 auto"}}>
                <Grid container spacing={0} direction="row" alignItems="flex-end" style={{minHeight:"10em"}}>
                        <Typography variant="h3" paragraph={true} className={textStyle.styledHead}>{content.headLine}</Typography>                           
                </Grid>
                <Grid>
                <Typography variant="body2">Edit</Typography>    
                </Grid>
                <Divider style={{marginTop:"0.7em", marginBottom:"0.7em"}} />
                <Grid container spacing={0} direction="row" alignItems="center">
                      <Grid item={true} xs={1} alignContent="center" alignItems="center"> 
                            <Avatar alt={content.author} src="/static/images/avatar/1.jpg" />
                      </Grid>
                      <Grid item={true} xs={6} alignContent="center" alignItems="center">
                        <Typography variant="p" className={textStyle.styledHead}>{content.author}</Typography>  
                      </Grid>
                </Grid>
                
                <Grid container spacing={0} direction="row" alignItems="center">
                      <Grid item xs>{parse(pageCont)}</Grid>
                </Grid>
                
                
                  
                
            </Grid>      
        </Grid>
    );
}

export default MyPosts_View;