import {makeStyles} from "@material-ui/core";

export const TextStyles = makeStyles(theme=>({
    styledHead :{
        fontFamily:"Hind Siliguri, sans-serif",
        color:"inherit"
    },

    brandName :{
        fontFamily:"Economica",
        color:"#ffffff"
    },

    engHeadline :{
        fontFamily:"Economica"
    }

}));

