import React, { useEffect, useState } from 'react';
import {useParams, useHistory} from 'react-router-dom';
import { Grid, TextField, Typography, Button, LinearProgress } from "@material-ui/core";
import {loadById} from "../contents/Db_ContentLoader";
import {updateContent} from "../contents/Db_ContentSave";
import parse from 'html-react-parser';
import MUIRichTextEditor from 'mui-rte'
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js'



const MyPosts_Edit = ()=>{
    const {id}      = useParams();
    const history   = useHistory();
    const [content, setContent]         = useState([]);
    const [headline, setHeadline]       = useState("");
    const [pageCont, setPageCont]       = useState("");
    const [editContent, setEditContent] = useState("");
    const [progress, setProgress]  = useState(false);
    

    const fetchContent = async ()=>{
        let snapshot    = await loadById(id);
        let data        = snapshot.data();

        console.log(data);
        setContent(data);    
        setHeadline(data.headLine);
        setPageCont(data.pageContent);

        let displayContent = data.pageContent.replace("<p>", "").replace("</p>", "").replace(/<br\s*\/?>/mg, "\n");
        setEditContent(displayContent);
    }

    const observeContent = (value) =>{
          setEditContent(value);
          let page = value.replace(/\r?\n|\r/g, "<br />");
          page     = "<p>" + page + "</p>";

          setPageCont(page);
          console.log(page);
    }

    const save = async () =>{
          setProgress(true);
          await updateContent(id, headline, pageCont);
          setProgress(false);
          history.push("/myposts/view/"+id);
    }

    useEffect(() =>fetchContent(), []);

    return(

        <Grid container spacing={0} alignItems="center" justify="center" style={{marginTop:"2em"}}>
            <Grid  item xs={"12"}>
                <TextField fullWidth id="standard-basic" placeholder="Headline"
                label="Headline"
                style={{marginTop:"1em"}} value={headline}
                onChange={(e) => setHeadline(e.target.value)} 
                />
            </Grid>

            <Grid item xs={"12"} style={{marginTop:"1em"}}>
                <TextField fullWidth multiline 
                        label="Content"
                        value={parse(editContent)} 
                        onChange={(e) => observeContent(e.target.value)} 
                />
            </Grid>

            <Grid  item xs={"12"} >
                <LinearProgress style={{visibility: progress ? "visible" : "hidden"}}/>
            </Grid>
            <Grid  item xs={"12"} >
                <Button variant="outlined" style={{marginTop:"1em"}} onClick={()=>save()}>SAVE</Button>
            </Grid>
        </Grid>
    );
}

export default MyPosts_Edit;