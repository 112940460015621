import React from "react";
import Latest from "../contents/Latest";

const Home = () => {
    return(
    <>
        <Latest />
    </>
    )
}

export default Home;