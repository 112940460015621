import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {createStore} from "redux";
import  rootReducer from "./utils/rdx/reducer";
import { Provider } from "react-redux";

const store = createStore(rootReducer);
// Modal.setAppElement("#root");
ReactDOM.render(<Provider store={store}><App/></Provider>, document.getElementById("root"));

